import { BinanceWallet, Metamask, SafePalWallet, TrustWallet, WalletConnect } from '../components/common/asset/icons';

export const NetworkContextName = 'NETWORK';
export const API_KDG = 'https://hub.hubecom.com/api'


export const LOGO_CHAIN = {
  56: "../images/bnb.png",
  1: "../images/eth.png",
  42161: "../images/arb.png",
  137: "../images/poly.png",
  250: "./images/fantom.png",

};
// wallet list
export const WALLET_LIST = [
  { icon: Metamask, title: 'Metamask', connectorId: 'injected' },
  { icon: BinanceWallet, title: 'Binance Wallet', connectorId: 'bsc' },
  { icon: SafePalWallet, title: 'SalePal Wallet', connectorId: 'injected' },
  { icon: WalletConnect, title: 'Wallet Connect', connectorId: 'walletconnect' },
  { icon: TrustWallet, title: 'Trust Wallet', connectorId: 'injected' }
]
export const status_difficulty = (number) => {
  const productValue = Math.pow(16, number);

  return productValue;
};
export const NEED_A_PLACEHOLDER = WALLET_LIST.length % 2 !== 0;
export const ADDRESS_BUSD_ADDRESS = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
export const MAINNET_BSC_URL = 'https://bsc-dataseed.binance.org';
export const MAINNET_CHAIN_ID = 56;
export const ARB_PANCAKE_FACTORY_ADDRESS_V2 = "";
export const ETH_PANCAKE_FACTORY_ADDRESS_V2 = "";
export const BSC_PANCAKE_FACTORY_ADDRESS_V2 = "";
export const BSC_ETH_ARB_PANCAKE_FACTORY_ADDRESS_V3 = "";
export const BUSD_BSC = "";
export const USDC_ARB = "";
export const USDC_BSC = "";
export const USDC_ETH = "";
export const USDT_ARB = "";
export const USDT_BSC = "";
export const USDT_ETH = "";
export const WBNB_BSC = "";
export const WETH_ARB = "";
export const WETH_ETH = "";
export const WSS_PROVIDER_ARB = "";
export const WSS_PROVIDER_BSC = "";
export const WSS_PROVIDER_ETH = "";



export const bscTokens = [
  // stake pool
  {
    id: 'wbnb',
    symbol: 'wbnb',
    contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  {
    id: 'binance-usd',
    symbol: 'busd',
    contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x55d398326f99059fF775485246999027B3197955',
  },
  {
    id: 'ethereum',
    symbol: 'ETH',
    contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },
  {
    id: 'dai',
    symbol: 'DAI',
    contract: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  },
  {
    id: 'polis',
    symbol: 'POLIS',
    contract: '0xb5bea8a26d587cf665f2d78f077cca3c7f6341bd',
  },
  {
    id: 'tether',
    symbol: 'USDT',
    contract: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
  },
  {
    id: 'usd-coin',
    symbol: 'USDC',
    contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
  {
    id: 'bsc-station',
    symbol: 'UpSwap',
    contract: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
  },
  {
    id: 'kingdom-game-4-0',
    symbol: 'KDG',
    contract: '0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6',
  },


]


// MAINNET NETWORK
export const MAPPING_CHAINID = {
  bsc: '0x38',
  bsctestnet: '0x61',
  eth: '0x1',
  poly: '0x89',
  ftom: '0xFA',
  arb: '0xa4b1',
  arbtestnet: '0x66EED',
  helatestnet: '0xa2d08',
  hela: '0x21dc'
}
export const CHAIN_INFO = {
  bsc: {
    decimal: 'ether',
    token: {
      id: 'wbnb',
      symbol: 'BNB',
      contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    stableToken: {
      id: 'binance-usd',
      symbol: 'busd',
      contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    }
  },
  bsctestnet: {
    decimal: 'ether',
    token: {
      id: 'wbnb',
      symbol: 'BNB',
      contract: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    stableToken: {
      id: 'binance-usd',
      symbol: 'busd',
      contract: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    }
  },
  eth: {
    decimal: 'picoether',
    token: {
      id: 'ethereum',
      symbol: 'ETH',
      contract: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    stableToken: {
      id: 'tether',
      symbol: 'USDT',
      contract: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    }
  },
  poly: {
    decimal: 'picoether',
    token: {
      id: 'polygon',
      symbol: 'MATIC',
      contract: '',
    },
    stableToken: {
      id: 'usd-coin',
      symbol: 'USDC',
      contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    }
  },
  ftom: {},
  arb: {
    decimal: 'ether',
    token: {
      id: 'ethereum',
      symbol: 'ETH',
      contract: '',
    },
    stableToken: {
      id: 'tether',
      symbol: 'USDT',
      contract: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    }
  },
  helatestnet: {
    decimal: 'ether',
    token: {
      id: 'ethereum',
      symbol: 'ETH',
      contract: '',
    },
    stableToken: {
      id: 'tether',
      symbol: 'USDT',
      contract: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    }
  },
  hela: {
    decimal: 'ether',
    token: {
      id: 'ethereum',
      symbol: 'ETH',
      contract: '',
    },
    stableToken: {
      id: 'tether',
      symbol: 'HLUSD',
      contract: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    }
  }
}
export const CHAIN_STABLECOIN = {
  busd: ""
}
export const MAPPING_CHAINID_DECIMAL = {
  bsc: 56,
  bsctestnet: 97,
  eth: 1,
  poly: 137,
  avax: 43114,
  ftom: 250,
  arb: 42161,
  arbtestnet: 421613,
  helatestnet: 666888,
  hela: 8668
}
export const CHAINID_CONVERT = {
  56: 'bep',
  97: 'bep',
  1: 'eth',
  43114: 'avax',
  138: 'poly',
  250: 'ftom',
  42161: 'arb',
  421613: "arbtestnet",
  666888: "helatestnet",
  8668: "hela"
}

export const NFTBYTYPE = {
  OG_NFT: 'HeLa OG NFT',
  EARLY_BIRD_NFT: 'Early Bird NFT',
  RANGER_NFT: 'Super HeLa Ranger NFT'
}

export const CHAINID_FULLNAME = {
  bsc: 'BSC',
  avax: 'Avax',
  bsctestnet: 'BSC Testnet',
  eth: 'Ethereum',
  poly: 'Polygon',
  ftom: 'Phantom',
  arb: 'Arbitrum',
  arbtestnet: "ArbitrumTest",
  helatestnet: "HelaTestnet",
  hela: "HeLa",
  undefined: 'Undefined'
}

export const CHAINID_FULLNAME_BY_ID = {
  56: 'BSC',
  97: 'BSC Testnet',
  1: 'Ethereum',
  138: 'Polygon',
  43114: 'Avalanche C-Chain',
  250: 'Phantom',
  42161: 'Arbitrum',
  421613: "ArbitrumTest",
  666888: "HelaTestnet",
  8668: "HeLa"
}

export const CHAINID_LOGO = {
  bsc: '../images/icon-binance.svg',
  bsctestnet: '../images/icon-binance.svg',
  eth: '../images/icon-ethereum.svg',
  avax: '../images/icon-avax.svg',
  poly: '../images/icon-polygon.svg',
  arb: '../images/arb.png',
  ftom: '',
  hela: '../images/hela-icon.svg',
  helatestnet: '../images/hela-icon.svg'
}
export const CHAINID_LOGO_BY_ID = {
  56: '../images/icon-binance.svg',
  97: '../images/icon-binance.svg',
  1: '../images/icon-ethereum.svg',
  43114: '../images/icon-avax.svg',
  138: '../images/icon-polygon.svg',
  42161: '../images/arb.png',
  250: '',
  8668: '../images/hela-icon.svg',
  666888: '../images/hela-icon.svg'
}

export const SALENODE_SC_BY_CHAINID = {
  56: '',
  97: '0xf0B53576592125a486ebd1f7376b128714f863Ea',
  1: '',
  42161: '0xB710ca62105975Ad554a597F725a82C1262B7b18',
}

export const ADDRESS_USD = {
  56: {
    BUSD: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    USDT: '0x55d398326f99059fF775485246999027B3197955'
  },
  97: {
    USDT: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd'
  },
  1: {
    USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7'
  },
  137: {
    USDC: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  },
  42161: {
    USDT: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    USDC: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8'
  },
  421613: {
    USDT: '0x5AB32546ca32442De752987Af6C08cFe1EF29A14',// token test
    USDC: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8'
  },
  666888: {
    HLUSD: '0x0000000000000000000000000000000000000000',
    USDH: '0xd8047eAA5939e051E70fcfa53dd66E911c5F3D1d'
  }, // HLUSD
  8668: {
    HLUSD: '0x0000000000000000000000000000000000000000',
    USDH: '0xd8047eAA5939e051E70fcfa53dd66E911c5F3D1d'
  }, // HLUSD
  250: '0xAd84341756Bf337f5a0164515b1f6F993D194E1f'
}

export const IDO_CLAIM_TYPE_MONTHLY = 'MONTHLY';
export const IDO_CLAIM_TYPE_DAILY = 'DAILY';

export const CAMPAIGN_STATUS = {
  COMPLETED: 'COMPLETED',
  UPCOMING: 'UPCOMING',
  OPENING: 'OPENING',
  TBA: 'TBA',
}
export const ROUND_TYPE = {
  COMMUNITY: 1,
  GUARANTEED: 2,
  PRIVATE: 3,
  MVKB: 4
}
export const Tokens = {
  AUTHUSER: 'authUser',
};

export const LOCAL_STORAGE = {
  SIGNATURE_COMMIT: 'SCM',
};

export const Networks = {
  BSC: 'BSC',
  BSCTESTNET: 'BSCTESTNET',
  POLY: 'POLY',
  ETH: 'ETH',
  ARB: 'ARB',
  ARBTESTNET: "ARBTESTNET",
  HELATESTNET: "HELATESTNET",
  HELA: "HELA"
};

export const CHAIN_INFO_DECIMALS = {
  bsc: 'ether',
  testnet: 'ether',
  eth: 'picoether',
  poly: 'picoether',
  helatestnet: 'ether',
  hela: 'ether',
}


export const SC_STAKE_FIXED = process.env.REACT_APP_SC_STAKE_FIXED
export const ADDRESS_HLUP_ADDRESS = "0x98B7D6D1f4Bca134a872db9574bCA672BA142DD9";

// nft
  export  const MARKET_ADDRESS_SC = process.env.REACT_APP_MARKET_ADDRESS_SC;
  export  const NFT_ADDRESS_SC = process.env.REACT_APP_NFT_ADDRESS_SC;
  export  const AUCTION_ADDRESS_SC = process.env.REACT_APP_AUCTION_ADDRESS_SC;
  
  // export  const MINT_NFT_ADDRESS_SC = "0xFA82c89c85E6C56ad5d170EA720BEDeDDD6f8bF2";

// end nft

export const SC_MULTISEND = {
  // 56: "0x1927bE22101AF894C15FE8DeA4D36Be11D057E45", // no fee
  // 97: "0xdA58a41854a6B6b9cf4b24D81b59d5Fa111C83Ec", // no fee
  // 56: "0x4675d6D1A8949173456567CA245f479AFBAaD2Be",
  // 56: "0x35Be25d0998f85F4f8980906d126bfA373097d20",
  56: "0x08E82031bbEA4C754159C802bd9370dbB10D088D",
  42161: "0xf5a16527A299360589bbbe16f5C6B54Bc334684a",
  // 97: "0x1FCc22c7E65B3104C86c8e66775728D47dCb1DDD",
  97: "0x9f7F65eb3EC2FFe73511397226aDa953b8E20eaD",
  1: "0x140110De44E3B2f14f00e718d3D2C0841aff1D00",
  666888: "0xf40201428937f6DBb96faea7911EEe528Af26293",
  8668: "0xFb0000919b6a711e9b720f2A322601fEB8570489",
};

export const SCAN_URL = {
  56: "https://bscscan.com",
  42161: "https://arbiscan.io",
  97: "https://testnet.bscscan.com",
  1: "https://etherscan.io/",
  666888: "https://testnet-blockexplorer.helachain.com",
  8668: "https://helascan.io",
};

export const SC_ADD_LP = {
  97: "0x2D3039336CfA5B23dFf193dD34233890a2B18859",
  56: "0x73c5f66C8d40FB24cb7175424a21c3173bA50213",
  1: "0xc70ee68B16e7fF278E55E2B9e0eeA49820Cf6c7C",
  42161: "0xf37F2e91eb47C26B10D9088D1560544716cF5f73",
};

export const SC_ADD_LP_V3 = {
  97: "0x7eDDE074BC14A78973Cf42c2e4E84238a70344E5",
  56: "0xEE14ddc4dAa9ea80CfE5151F5516A9cA5D051D3F",
  42161: "0xCA80cfb81CD99d064180974C640bC529e1d57959",
  1: "0x55a85101EAe6500F2F7b79A4fDF84abC959e5031",
};

export const NodeTier = {
  TIER_1: "HeLa Node Tier 1",
  TIER_2: "HeLa Node Tier 2",
  TIER_3: "HeLa Node Tier 3",
  TIER_4: "HeLa Node Tier 4",
  TIER_5: "HeLa Node Tier 5",
  TIER_6: "HeLa Node Tier 6",
  TIER_7: "HeLa Node Tier 7",
  TIER_8: "HeLa Node Tier 8",
  TIER_9: "HeLa Node Tier 9",
  TIER_10: "HeLa Node Tier 10",
  TIER_11: "HeLa Node Tier 11",
  TIER_12: "HeLa Node Tier 12",
  TIER_13: "HeLa Node Tier 13",
  TIER_14: "HeLa Node Tier 14",
  TIER_15: "HeLa Node Tier 15",
  TIER_16: "HeLa Node Tier 16",
  TIER_17: "HeLa Node Tier 17",
  TIER_18: "HeLa Node Tier 18",
  TIER_19: "HeLa Node Tier 19",
  TIER_20: "HeLa Node Tier 20",
};

export const price_range = (fee) => {
  switch (fee) {
    case 100:
      return [0.1, 0.5, 1, 100];
    case 500:
      return [5, 10, 20, 100];
    case 2500:
      return [10, 20, 50, 100];
    case 10000:
      return [10, 20, 50, 100];
    default:
      return [5, 10, 20, 100];
  }
};

