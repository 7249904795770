
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Pagination, Input, Select, Table, Collapse, Alert, Tag, Spin, Empty, Progress } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { getInformationByChain } from '../../config/network/multichainAddresses';

import { Loader } from '../common/component';
import FooterHome from '../common/footer/footerHome';
import { _isHelaMinted } from './utils';

import { HelaNodeApi } from '../../config/api/helaNodeApi';
import NodeItem from './NodeItem';
import { chain } from 'mathjs';
import networkChangeByNumber from '../../utils/networkChangeByNumber';

const { Option } = Select;
const { Panel } = Collapse;

const HelaNode = () => {
    const { account, chainId } = useActiveWeb3React();

    const [param, setParam] = useState(
        {
            page: 0,
            size: 20,
            sort: 'id,asc',
        }
    );
    const nodeDetailSection = useRef(null);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

   
    const [loadingMint, setLoadingMint] = useState(false);
    
    const [viewDisplay , setViewDisplay] = useState('grid');
    const [helaNodes, setHelaNodes] = useState([]);
    const [helaNodeItem, setHelaNodeItem] = useState([]);
    const [nodeActive, setNodeActive] = useState(null);
    const [totalRow, setTotalRow] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
   

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);


    const getHelaNodes = async ()=>{
        setLoadingData(true);
        try {
            let para = {
                page: 0,
                size: 20,
                sort: 'id,desc',
            }
            let res = await HelaNodeApi.getHelaNodes(para);
            
            if (res.status === 200 && res.data) {
                setHelaNodes(res.data);
                
            } else {
                setHelaNodes([]);
            }
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
            setHelaNodes([]);
        }
    }

    const getHelaNodeItems = async ()=>{
        setLoadingData(true);
        try {
            
            let res = await HelaNodeApi.getHelaNodeItems(param);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200 && res.data) {
                setHelaNodeItem(res.data);
                
            } else {
                setHelaNodeItem([]);
            }
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            console.log('Error: ', error);
            setHelaNodeItem([]);
        }
    }

    useEffect(() => {
        getHelaNodes();
        getHelaNodeItems();
    }, []);

    useEffect(() => {
        if(helaNodeItem && nodeActive == null){
            let keyNode = localStorage.getItem('keyItem') ? localStorage.getItem('keyItem') : 0;
            setNodeActive(helaNodeItem[keyNode]);
         
        }
    }, [helaNodeItem]);

    const handleActiveNode = (item, key) => {
        setNodeActive(item);
        localStorage.setItem('keyItem', key);
        window.scrollTo({ top: nodeDetailSection.current.offsetTop, behavior: 'smooth', });
    };


    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const changeView = (view) => {
        setViewDisplay(view);
    };

    let qtyNode = nodeActive?.itemQtyAvailable ? nodeActive?.itemQtyAvailable : nodeActive?.itemQty;

    return (
        <>
            <div className='helanode-page'>
                <div className="container">
                    <Row gutter={20}>
                        <Col xs={24} sm={14}>
                            <div className='helanode-info'>
                                <div className='node-banner'>
                                    <img src='../images/hela-node-banner.png' />
                                </div>
                                <div className='node-title text-weight-500 text-regular-blue'>
                                    Join us in this exclusive sale, limited to just 1000 Guardian Nodes. Are you worthy to be a HeLa Guardian?
                                </div>
                                <div className='node-description'>
                                    {helaNodes[0]?.description}
                                </div>

                              
                                <div className="social flex-center">
                                    <a href="https://discord.com/invite/helalabs" target="_blank"><img src="../images/dis.svg" /></a>
                                    <a href="https://x.com/HeLa_Labs" target="_blank"><img src="../images/x.svg" /></a>
                                    <a href="https://t.me/hela_official" target="_blank"><img src="../images/tele.svg" /></a>
                                    <a href="https://docs.helalabs.com/" target="_blank"><img src="../images/GitBook.png" /></a>
                                </div>

                            </div>
                            
                        </Col>
                        <Col xs={24} sm={10}>
                            <div className='helanode-info-detail' ref={nodeDetailSection}>
                                <div className='node-info-title flex-btw'>
                                    
                                    <span>{nodeActive ? nodeActive?.itemName : ''}</span>
                                    {nodeActive?.itemSoldQty > qtyNode && (
                                        <Tag color="red">OVERFLOW</Tag>
                                    )}
                                    
                                </div>
                                {/* <div className='node-info-description'>
                                    {nodeActive ? nodeActive?.itemDescription : ''}
                                </div> */}
                                <div className='node-form'>
                                    {loadingData ? (
                                        <>
                                            <div className='text-center'><Spin size='large' /></div>
                                        </>
                                    ):(
                                        <>
                                            <NodeItem helaNode={helaNodes[0]} nodeActive={nodeActive}/>
                                            
                                            {/* {chainId == 42161 ? (
                                                <>
                                                    
                                                </>
                                            ):(
                                                <>
                                                    <button className='btn-basic bg-danger mt-15' onClick={()=> networkChangeByNumber(42161)}>Switch network to Arbitrum</button>
                                                </>
                                            )} */}
                                            
                                        </>
                                    )}
                                    
                                </div>

                                
                            </div>
                        </Col>
                    </Row>

                    <div className='mt-15'>
                        <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="end" className='collapse-cus'>
                            <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-18 text-weight-500'>How does the launchpad work?</span></>} key="1">
                                <p className='mt-5 mb-0 text-regular-darkgray'>This launchpad operates on the OVERFLOW method.</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>In the overflow method, users can subscribe as much as they want to the launchpad, and their final allocation will be based on the amount of funds they put in as a percentage of all funds put in by other users at the time the sale ends.</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>Users will get back any leftover funds when they claim their nodes after the sale.</p>
                            </Panel>
                            <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-18 text-weight-500'>How to buy HeLa Guardian Node on Upswap?</span></>} key="2">
                                <p className='mt-5 mb-0 text-regular-darkgray'>Secure your spot in HeLa Node Sale by following these steps 👆</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>🗝 Important: </p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>✅ Ensure you have {chainId && chainId == 8668 ? 'HLUSD' : 'ETH'} on {chainId && chainId == 8668 ? 'HeLa ' : 'Arbitrum'} to cover gas fees</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>✅ You can commit to buy as many nodes as you want</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>👉 Step-by-step guide: <a target="_blank" href='https://medium.com/upswap-finance/how-to-buy-hela-node-sale-on-upswap-24acc41caf2e'>https://medium.com/upswap-finance/how-to-buy-hela-node-sale-on-upswap-24acc41caf2e</a> </p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>👉 For mobile users, use the metamask mobile browser: <a target="_blank" href='https://support.metamask.io/vi/getting-started/how-to-use-the-metamask-mobile-browser'>https://support.metamask.io/vi/getting-started/how-to-use-the-metamask-mobile-browser</a> </p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>👉 Watch video guide: <a target="_blank" href='https://www.youtube.com/watch?v=72yCxjBqdMc'>https://www.youtube.com/watch?v=72yCxjBqdMc</a> </p>
                            </Panel>
                            <Panel header={<><span className='mt-5 mb-0 text-medium-blue font-18 text-weight-500'>Benefits of buying HeLa Guardian Node</span></>} key="3">
                                <p className='mt-5 mb-0 text-regular-darkgray'>1) Instant airdrop of 288 HeLa tokens upon TGE</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>2) Linear vesting of 864 HeLa tokens over 4 years</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'>3) Priority airdrop and whitelist of protocols built atop HeLa!</p>
                                <p className='mt-5 mb-0 text-regular-darkgray'><a target="_blank" href='https://docs.helalabs.com/hela-node/hela-guardian-node/node-rewards'>Click here</a> to find out more about HeLa Guardian Node Incentives!</p>
                            </Panel>
                        </Collapse>
                    </div>

                    
                   <div className='node-tier-list'>
                        {loadingData ? (
                            <>
                                <div className='text-center'><Spin size='large' /></div>
                            </>
                        ):(
                            <>
                                <div className="nodes">
        
                                    <div className="nodes-list">
                                        <Row gutter={0}>
                                            <Col xs={24}>
                                                <div className="float-right">
                                                    <div className="border-line mb-15 w-fitcontent">
                                                        <button onClick={()=>changeView("grid")} className={`btn-grid ${viewDisplay == "grid" ? 'btn-grid-active':''}`}><AppstoreOutlined /></button>
                                                        <button onClick={()=>changeView("list")} className={`btn-grid ${viewDisplay == "list" ? 'btn-grid-active':''}`}><UnorderedListOutlined /></button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                        {viewDisplay == "grid" && (
                                            <Row gutter={30}>
                                                {helaNodeItem.length > 0 ? helaNodeItem.map((item, key) => {

                                                    

                                                    let quantity = item?.itemQtyAvailable ? item?.itemQtyAvailable : item?.itemQty;
                                                    let percentSold = Number(item?.itemSoldQty/quantity * 100);

                                                    let saleText = item?.sale ? "Open" : "Locked";
                                                    let saleColor = item?.sale ? "green" : "red";

                                                    return(
                                                        <Col xs={24} sm={6} key={`key-${key}`}>
                                                            <div key={`keyitem-${key}`} className={`nodes-item ${item?.id == nodeActive?.id ? 'border-active':''}`} onClick={()=> handleActiveNode(item, key)}>
                                                                {saleText != "Open" && (
                                                                    <div className='no-available'>
                                                                        NOT AVAILABLE
                                                                    </div>
                                                                )}
                                                                
                                                                <div className="node-top">
                                                                    <div className="node-title flex-btw">
                                                                        <div className="flex-left text-black-green text-weight-400 gap-5">
                                                                            <img src="../images/node-item.svg" />
                                                                            <div className=''>
                                                                                <div className=''>{item?.itemName}</div>
                                                                                <Tag className='node-tag' color={saleColor}><small>{saleText}</small></Tag>
                                                                            </div>
                                                                        </div>
                                                                        <img src="../images/arb.svg" />
                                                                        
                                                                    </div>
                                                                    <div className="nodes-info">
                                                                        <div className="flex-btw text-white">
                                                                            <span className="text-black-green">Price per Node</span>
                                                                            <span className="text-weight-500 text-darkblue">
                                                                                
                                                                    
                                                                                {chainId && chainId == 8668 ? (
                                                                                    <>
                                                                                        {item?.itemHelaPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(item?.itemHelaPrice) : 0} HLUSD
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item?.itemPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(item?.itemPrice) : 0} ETH
                                                                                    </>
                                                                                )}

                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-btw text-white mt-15">
                                                                            <span className="text-black-green">Node for Sale</span>
                                                                            <div className="d-flex-center gap-5">
                                                                                <span className="text-weight-500 text-darkblue">{ new Intl.NumberFormat("ja-JP").format(quantity) } Nodes</span> 
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-btw text-white mt-15">
                                                                            <span className="text-black-green">Pay in</span>
                                                                            <div className="text-weight-500 flex-btw text-darkblue">
                                                                                {chainId && chainId == 8668 ? (
                                                                                    <>
                                                                                        <img className="w-25" style={{marginRight: '5px'}} src="../images/hela-icon.svg" alt="" />
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <img className="w-25" style={{marginRight: '5px'}} src="../images/eth.svg" alt="" />
                                                                                    </>
                                                                                )}
                                                                        
                                                                                <span>{chainId && chainId == 8668 ? 'HLUSD' : 'ETH'}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="node-progress">
                                                                        <Progress showInfo={false} percent={percentSold} />
                                                                        <div className="flex-btw text-white">
                                                                            <span className="text-weight-500 text-black-green">{item?.itemSoldQty ? new Intl.NumberFormat("ja-JP").format(item?.itemSoldQty) : 0}/{new Intl.NumberFormat("ja-JP").format(quantity)} Nodes</span>
                                                                            <span className="text-weight-500 text-darkblue">{new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 1}).format(percentSold)}%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="node-bottom">
                                                                    <div className="text-center text-white weight-300">
                                                                        <button disabled={saleText != "Open"} onClick={()=> handleActiveNode(item, key)} className="btn-basic">Purchase</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }):(
                                                    <Col xs={24}>
                                                        <div className="text-center">
                                                            <Empty description={<span className="text-white">No data</span>} />
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                        )}


                                        {viewDisplay == "list" && (
                                            <>
                                                {helaNodeItem.length > 0 ? helaNodeItem.map((item, key) => {

                                                   
                                                    let quantity = item?.itemQtyAvailable ? item?.itemQtyAvailable : item?.itemQty;
                                                    let percentSold = Number(item?.itemSoldQty/quantity * 100);

                                                    let saleText = item?.sale ? "Open" : "Locked";
                                                    let saleColor = item?.sale ? "green" : "red";

                                                    return(
                                                        <div className={`grid-item ${item?.id == nodeActive?.id ? 'border-active':''} ${item?.itemSoldQty == item?.itemQty ? 'border-soldout':''}`} onClick={()=> handleActiveNode(item, key)}>
                                                            <Row gutter={20}>
                                                                <Col xs={4} sm={1} className="flex-btw">
                                                                    <img src="../images/node-item.svg" />
                                                                </Col>
                                                                <Col xs={20} sm={4} className="flex-btw">
                                                                    <div className="text-black-green text-weight-500 text-left">
                                                                        {item?.itemName}
                                                                        <Tag className='node-tag' color={saleColor}><small>{saleText}</small></Tag>
                                                                    </div>
                                                                    <img src="../images/arb.svg" />
                                                                </Col>
                                                                <Col xs={12} sm={4} className="mt-10-sm">
                                                                    <div className="text-black-green weight-300 text-left">Price per Node</div>
                                                                    <div className="text-weight-500 text-left mt-10">
                                                                        {item?.itemPrice > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits:5}).format(item?.itemPrice) : 0} {chainId && chainId == 8668 ? 'HLUSD' : 'ETH'}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={4} className="mt-10-sm">
                                                                    <div className="text-black-green weight-300 text-left">Node for Sale</div>
                                                                    <div className="text-weight-500 text-left mt-10">{quantity > 0 ? new Intl.NumberFormat("ja-JP").format(quantity) : 0} <span className="weight-300">Nodes</span></div>
                                                                </Col>
                                                                <Col xs={12} sm={2} className="mt-10-sm">
                                                                    <div className="text-black-green weight-300 text-left">Pay in</div>
                                                                    <div className="text-weight-500 text-left mt-10">
                                                                        <div className="weight-600 flex-left">
                                                                            {chainId && chainId == 8668 ? (
                                                                                <>
                                                                                    <img className="w-25" style={{marginRight: '5px'}} src="../images/hela-icon.svg" alt="" />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <img className="w-25" style={{marginRight: '5px'}} src="../images/eth.svg" alt="" />
                                                                                </>
                                                                            )}
                                                                            
                                                                            <span>{chainId && chainId == 8668 ? 'HLUSD' : 'ETH'}</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={3} className="mt-10-sm">
                                                                    <div className="text-black-green weight-300 text-left">Node Sold</div>
                                                                    <div className="text-weight-500 text-left mt-10">{item?.itemSoldQty ? item?.itemSoldQty : 0}/{quantity} </div>
                                                                </Col>
                                                                <Col xs={24} sm={6} className="mt-10-sm">
                                                                    <div className="node-progress">
                                                                        <Progress showInfo={false} percent={percentSold} />
                                                                        <div className="flex-btw text-black-green">
                                                                            <span className="text-weight-500">{item?.itemSoldQty ? item?.itemSoldQty : 0}/{quantity} Nodes</span>
                                                                            <span className="text-weight-500">{new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 1}).format(percentSold)}%</span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                
                                                            </Row>
                                                            
                                                        </div>
                                                    )
                                                }):(
                                                    <Col xs={24}>
                                                        <div className="text-center">
                                                            <Empty description={<span className="text-white">No data</span>} />
                                                        </div>
                                                    </Col>
                                                )}
                                            </>
                                        )}

                                        
                                    </div>
                                </div>
                            </>
                        )}
                        
                    </div>
                </div>
            </div>
            <FooterHome />
            
            {loadingMint && (
                <>
                    <Loader />
                </>
            )}
        </>
    )
}

export default HelaNode
