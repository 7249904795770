const networkChange = async (chainId) => { 
    
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
        });
        window.location.reload();
    } catch (error) {
        if (error.code === 4902) {
            try {
                if (chainId ==='0x89') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: 'Polygon Mainnet',
                                // chainId: '0x89',
                                chainId: `0x${Number(137).toString(16)}`,
                                rpcUrls: [
                                    "https://polygon-rpc.com",
                                    "https://rpc-mainnet.matic.network",
                                    "https://rpc-mainnet.maticvigil.com",
                                    "https://rpc-mainnet.matic.quiknode.pro",
                                    "https://matic-mainnet.chainstacklabs.com",
                                    "https://matic-mainnet-full-rpc.bwarelabs.com",
                                    "https://matic-mainnet-archive-rpc.bwarelabs.com",
                                    "https://poly-rpc.gateway.pokt.network/",
                                    "https://rpc.ankr.com/polygon",
                                    "https://rpc-mainnet.maticvigil.com/"
                                ],
                                blockExplorerUrls: ["https://polygonscan.com/"],
                                nativeCurrency: {
                                    name: 'MATIC',
                                    symbol: 'MATIC',
                                    decimals: 18
                                }
                            }
                        ]
                    });
                }
                if (chainId ==='0x38') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Binance Smart Chain",
                                chainId: `0x${Number(56).toString(16)}`,
                                nativeCurrency: {
                                    name: "Binance Chain Native Token",
                                    symbol: "BNB",
                                    decimals: 18
                                },
                                rpcUrls: [
                                    "https://bsc-dataseed1.binance.org",
                                    "https://bsc-dataseed2.binance.org",
                                    "https://bsc-dataseed3.binance.org",
                                    "https://bsc-dataseed4.binance.org",
                                    "https://bsc-dataseed1.defibit.io",
                                    "https://bsc-dataseed2.defibit.io",
                                    "https://bsc-dataseed3.defibit.io",
                                    "https://bsc-dataseed4.defibit.io",
                                    "https://bsc-dataseed1.ninicoin.io",
                                    "https://bsc-dataseed2.ninicoin.io",
                                    "https://bsc-dataseed3.ninicoin.io",
                                    "https://bsc-dataseed4.ninicoin.io",
                                    "wss://bsc-ws-node.nariox.org"
                                ],
                                blockExplorerUrls: ["https://bscscan.com"]
                            }
                        ]
                    });
                }
                if (chainId ==='0x61') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Binance Smart Chain Testnet",
                                chainId: `0x61`,
                                nativeCurrency: {
                                    name: "Binance Chain Native Token",
                                    symbol: "tBNB",
                                    decimals: 18
                                },
                                rpcUrls: [
                                    "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
                                    "https://data-seed-prebsc-2-s2.bnbchain.org:8545"
                                ],
                                blockExplorerUrls: ["https://testnet.bscscan.com"]
                            }
                        ]
                    });
                }
                if (chainId ==='0xa4b1') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                        {
                            chainName: "Arbitrum One",
                            chainId: "0xa4b1",
                            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                            nativeCurrency: {
                                name: "Arbitrum One",
                                symbol: "ETH",
                                decimals: 18,
                            },
                        },
                        ],
                    });
                } 
                //test arb
                if (chainId ==='0x66EED') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                        {
                            chainName: "Arbitrum Goerli",
                            chainId: "0x66EED",
                            rpcUrls: ["https://endpoints.omniatech.io/v1/arbitrum/goerli/public"],
                            nativeCurrency: {
                                name: "Arbitrum Goerli",
                                symbol: "ETH",
                                decimals: 18,
                            },
                        },
                        ],
                    });
                }
                if (chainId === '0xa2d08') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Hela Official Runtime Testnet",
                                chainId: '0xa2d08',
                                rpcUrls: ["https://testnet-rpc.helachain.com"],
                                nativeCurrency: {
                                    name: "Hela Native Token",
                                    symbol: "HLUSD",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } 
                if (chainId === '0x21dc') {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Hela Official Runtime",
                                chainId: '0x21dc',
                                rpcUrls: ["https://mainnet-rpc.helachain.com"],
                                nativeCurrency: {
                                    name: "Hela Native Token",
                                    symbol: "HLUSD",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } 
                
            } catch (err) {
                console.log(err);
            }
        }
        window.location.reload();
    }
};

export default networkChange;