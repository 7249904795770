
import React, { useState, useEffect } from "react";
import { Alert, Input, message, Upload } from "antd";
import { CopyOutlined, VerticalAlignBottomOutlined, UploadOutlined } from '@ant-design/icons';
import { CopyToClipboard } from "react-copy-to-clipboard";
import approve from "../../utils/approve";
import web3 from "web3";
import { SC_MULTISEND, SCAN_URL, MAPPING_CHAINID_DECIMAL, CHAINID_FULLNAME_BY_ID } from "../../constants";
import getTokenInfo from "../../utils/checkInfoByAccount";
import * as XLSX from "xlsx";
import bgMainheaderRow from "./images/bg-radi.png";
import ABI from "./abi/abiMutiSendNew.json";
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { notiError, notiSuccess, notiWarning } from "../../utils/notication";
import "./style.css";
import { convertFromWei, convertToWei } from "../../utils/convertNumber";
import Loading from "../Loading";
import { useActiveWeb3React } from "../../hooks";
import FooterHome from "../common/footer/footerHome";
import ChainNotSupport from "../chain-not-support";
import { Loader } from "../common/component";

const renderSymbolFee = (value) => {
  switch (value) {
    case 56:
      return "BNB";
      break;
    case 1:
      return "ETH";
      break;
    case 42161:
      return "ARB_ETH";
      break;
    case 8668:
      return "HLUSD";
      break;
    default:
      return "--";
      break;
  }
};

const MultiSender = () => {
  const { TextArea } = Input;
  const Web3 = new web3(window.ethereum);
  const { account , chainId } = useActiveWeb3React();
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenInfo, setTokenInfo] = useState();
  const [recipients, setRecipients] = useState("");
  const [isApprove, setApprove] = useState(false);
  const [isApproving, setApproving] = useState(false);
  const [isConfirming, setConfirming] = useState(false);
  const [isTransactionHash, setTransactionHash] = useState("");

  const recipientList = recipients
    .trim()
    .split("\n")
    .map((row) => {
      const [account, value, valuesBNB] = row.split(",");
      return { account, value, valuesBNB };
    });

  const multiContract = new Web3.eth.Contract(
    ABI, SC_MULTISEND[chainId]
  );

  const addresses = recipientList.map((item) => {
    let itemRep = item?.account?.trim();
    return itemRep;
  });

  const values = recipientList.length > 0 && recipientList.map((item) => {
    const trimmedValue = item?.value?.trim();
    if (tokenInfo !== -1) {
      if (trimmedValue && !isNaN(Number(trimmedValue)) && tokenInfo) {
        const toWeiValue = convertToWei(
          trimmedValue.toString(),
          Number(tokenInfo.decimals)
        );
        return toWeiValue.toString();
      } else {
        return "0";
      }
    } else {
      if (trimmedValue && !isNaN(Number(trimmedValue)) && tokenInfo) {
        const toWeiValue = convertToWei(trimmedValue.toString(), 18);
        return toWeiValue.toString();
      } else {
        return "0";
      }
    }
  });
  // const valuesBNB = recipientList.map((item) => {
  //   const trimmedValue = item?.valuesBNB?.trim();

  //   if (trimmedValue && !isNaN(Number(trimmedValue))) {
  //     return web3.utils.toWei(trimmedValue);
  //   } else {
  //     return "0";
  //   }
  // });

  // const dataWall = async ()=>{
  //   const balanceBase = await Web3.eth.getBalance('0xDD94c0b77130e25c1dbB8405633bca82aeed3c71');
  //   console.log('balanceBase-----', convertFromWei(balanceBase, 18));
  // }

  function getSum(total, num) {
    if (tokenAddress === "0x0000000000000000000000000000000000000000") {
      return total + Number(convertFromWei(num, 18));
    }
    return total + Number(convertFromWei(num, tokenInfo?.decimals));
  }
  const totalValue = values.reduce((total, value) => getSum(total, value), 0);

  // const valueWei = valuesBNB.reduce(
  //   (total, value) => total + parseFloat(value),
  //   0
  // );

  useEffect(() => {
    // dataWall();
    if (tokenAddress !== "0x0000000000000000000000000000000000000000") {
      if (totalValue > Number(tokenInfo?.allowance)) {
        setApprove(true);
      } else {
        setApprove(false);
      }
    } else {
      setApprove(false);
    }
  }, [tokenAddress, tokenInfo, totalValue]);

  const handleLoadDataToken = async () => {
    try {
      if (tokenAddress === "0x0000000000000000000000000000000000000000") {
        setTokenInfo(-1);
        return;
      } else {
        if(chainId && chainId == MAPPING_CHAINID_DECIMAL.hela){
          const data = await getTokenInfo(
            SC_MULTISEND[chainId],
            tokenAddress,
            account
          );
          setTokenInfo(data);
        }else{
          setTokenInfo({});
        }
        
      }
    } catch (error) {
      setTokenInfo({});
      console.error(error);
      Store.addNotification(addNotify('Token not found', 'danger'));
    }
  };
  useEffect(() => {
    if(account && tokenAddress){
      handleLoadDataToken();
    }
	}, [tokenAddress, account, chainId]);
  const reCheck = async () => {
    try {
      const data = await getTokenInfo(
        SC_MULTISEND[chainId],
        tokenAddress,
        account
      );
      setTokenInfo(data);
    } catch (error) {
      setTokenInfo({});
      console.error(error);
    }
  };
  const handleApprove = async () => {
    try {
      setApproving(true);
      await approve(SC_MULTISEND[chainId], tokenAddress, account).then(
        (res) => {
          if (res.status) {
            reCheck();
            setApproving(false);
            Store.addNotification(addNotify('Approve successfully!', 'success'));
          }
        }
      );
    } catch (error) {
      setApproving(false);
      console.error(error);
      Store.addNotification(addNotify('Approve unsuccessfully!', 'danger'));
    }
  };
  const handleMultisend = async () => {
    try {
      setConfirming(true);
      const gasPrice = await Web3.eth.getGasPrice();

      // const feeBNB = await multiContract.methods.feeBNB().call();
      // const totalSend = valueWei + parseFloat(feeBNB);

      const balanceBase = await Web3.eth.getBalance(account);

      // if (
      //   Number(convertFromWei(totalSend, 18)) <
      //   Number(convertFromWei(balanceBase, 18))
      // ) {
      if (tokenAddress === "0x0000000000000000000000000000000000000000") {
        await multiContract.methods
          .multiTransferToken(
            tokenAddress,
            addresses,
            values,
            convertToWei(totalValue.toFixed(5).toString(), 18)
          )
          .send({
            from: account,
            value: convertToWei(totalValue.toFixed(5).toString(), 18),
            gasPrice: Web3.utils.toHex(String(gasPrice)),
          })
          .then((res) => {
            if (res.status) {
              Store.addNotification(addNotify('Confirm Successfully!', 'success'));
              setTransactionHash(res.transactionHash);
              setConfirming(false);
              setRecipients("");
            } else {
              setConfirming(false);
              Store.addNotification(addNotify('Confirm Failed!', 'danger'));
            }
          });
      } else {


        let totalValueConvert = convertToWei(
          totalValue.toFixed(5).toString(),
          Number(tokenInfo.decimals)
        );
        await multiContract.methods
          .multiTransferToken(
            tokenAddress,
            addresses,
            values,
            totalValueConvert
          )
          .send({
            from: account,
            // value: totalValueConvert,
            gasPrice: Web3.utils.toHex(String(gasPrice)),
          })
          .then((res) => {
            if (res.status) {
              Store.addNotification(addNotify('Confirm Successfully!', 'success'));
              setTransactionHash(res.transactionHash);
              setConfirming(false);
              setRecipients("");
              if(account && tokenAddress){
                handleLoadDataToken();
              }
            } else {
              setConfirming(false);
              Store.addNotification(addNotify('Confirm Failed!', 'danger'));
            }
          });
          setConfirming(false);
      }

      // }
      //  else {
      //   setConfirming(false);
      //   notiError("Not enough fees", 5);
      // }
    } catch (error) {
      setConfirming(false);
      console.error(error);
      if (error) {
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, 'danger'));
        } else if (error.reason) {
          // Store.addNotification(addNotify(error.reason, 'danger'));
          Store.addNotification(addNotify('Invalid Address Receiver', 'danger'));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, 'danger'));
          }
        }
      }
    }
  };
  const handleFileUpload = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const filteredData = jsonData.filter(
          (row) =>
            Web3.utils.isAddress(row[0]?.trim()) &&
            !isNaN(row[1]?.toString().trim())
        );

        const formattedData = filteredData.map((row, index) => {
          const [account, amountToken] = row;
          return `${account.trim()}, ${amountToken.toString().trim()}`;
        });

        setRecipients(formattedData.join("\n"));
      };

      reader.readAsArrayBuffer(file);
      Store.addNotification(addNotify('Upload file successfully!', 'success'));
    } catch (error) {
      console.error(error);
      
      Store.addNotification(addNotify('Upload file failed!', 'danger'));
    }
  };

  const exportToExcel = () => {
    const data = [
      {
        Address: "0x075AA49136664628E588493671d323928FD8835A",
        AmountToken: "1000",
      },
      {
        Address: "0xc2E311e9FA6B43002f02d5835D560f03c59604D7",
        AmountToken: "1000",
      },
      {
        Address: "0xD9888a6A6dA9A05091adb798e382E8a499C03F71",
        AmountToken: "1000",
      },
      {
        Address: "0x9dfeb78168826d95C75306832414705D19096979",
        AmountToken: "1000",
      },
      {
        Address: "0xa901FB223f7d5deff626E7A3E78f77344df42b0E",
        AmountToken: "1000",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sample");
    XLSX.writeFile(workbook, "Sample.xlsx");
  };

  let chainName = chainId ? CHAINID_FULLNAME_BY_ID[MAPPING_CHAINID_DECIMAL.hela] : '---';
  const onCopy = () => {
      Store.addNotification(addNotify('Copied', 'success'));
  };

  return (
    <>
      <div className="main-section" style={{
                background: `url(${bgMainheaderRow}) no-repeat`,
            }}>
        <div className="container">
          <div className="section-check-balance mar-top-30">
            <div className="round-check-balance">
              <div className="title">Token MultiSender</div>
              <div className="item-form">
                <div className="txt">Token Address <span className="text-danger">*</span></div>
                <div className="input-round multi">
                  <Input
                    placeholder="Input token address"
                    value={tokenAddress}
                    onChange={(e) => setTokenAddress(e.target.value)}
                    className="form-control w-100-160"
                  />
                  {/* <button
                    type="button"
                    className="btn-add mar-top-20 click-now"
                    disabled={!tokenAddress || !account}
                    onClick={handleLoadDataToken}
                  >
                    Add Token
                  </button> */}
                </div>
                <div className="input-round multi">
                  <p className="mt-10 mb-0"><span>MultiSender now supports HeLa Chain. Native token address (HLUSD):</span></p>
                  <p className="mt-5 mb-0">
                    0x0000000000000000000000000000000000000000
                    <CopyToClipboard onCopy={onCopy} text={'0x0000000000000000000000000000000000000000'}>
                        <CopyOutlined className="text-darkgreen"/>
                    </CopyToClipboard>
                  </p>
                </div>
                <p className="mt-10 mb-10"><span>Limit 100 addresses for 1 transfer.</span> </p>
                
              </div>
              {tokenInfo !== -1 && tokenInfo?.name && (
                <>
                  <div className="item-form mt-10 mb-10">
                    <div className="txt">Token Details</div>
                    <div className="box-detail-balance">
                      <div className="item">
                        <div className="row-name">
                          <div className="name">Name:</div>
                          <div className="text">{tokenInfo?.name}</div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row-name">
                          <div className="name">Symbol:</div>
                          <div className="text">
                            {tokenInfo?.symbol}
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row-name">
                          <div className="name">Network:</div>
                          <div className="text">
                            {chainName}
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row-name">
                          <div className="name">Total Supply:</div>
                          <div className="text">
                            {" "}
                            {new Intl.NumberFormat("ja-JP").format(tokenInfo?.supply)}
                            {/* {tokenInfo?.supply} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mb-10 mt-0 text-darkblue">Your <span className="text-weight-600">{tokenInfo?.symbol}</span> balance: <span className="text-weight-600">{new Intl.NumberFormat("ja-JP").format(tokenInfo?.balanceOf)}</span></p>
                  <p className="mb-10 mt-0 text-darkblue">Total <span className="text-weight-600">{tokenInfo?.symbol}</span> send: <span className="text-weight-600">{new Intl.NumberFormat("ja-JP").format(totalValue)}</span></p>
                </>
              )}

              {addresses.length > 1 && (
                <p className="mb-10 mt-0 text-darkblue">Total address: <span className="text-weight-600">{new Intl.NumberFormat("ja-JP").format(addresses.length)}</span></p>
              )}

              <div className="item-form mar-b-20">
                <div className="txt">Address List</div>
                <div className="input-round">
                  <TextArea
                    className="form-control w-100"
                    readOnly
                    value={recipients}
                    onChange={(e) => setRecipients(e.target.value)}
                    placeholder={
                      `
                        Only support format: 
                        Wallet address (separated with break lines) and Token amount (separated by comma)
                        E.g:
                        0xdb1Af3F960cd15828135dd66477421C586152888,2000
                        0x39AFCaCeDE335E906a0740372Ae1d13b1B6A8189,500
                        0x8638e371bDdB636FD75a7771F0b139Ddb684a929,300
                      `
                    }
                    autoSize={{
                      minRows: 10,
                      maxRows: 10,
                    }}
                  />
                </div>
              </div>
              <div className="group-upload">
                <Upload
                  accept=".xlsx, .xls"
                  showUploadList={false}
                  beforeUpload={handleFileUpload}
                >
                  <button className="btn-up">
                    <UploadOutlined style={{fontSize: '18px'}}/> {' '} Upload Excel
                  </button>
                </Upload>

                <button onClick={exportToExcel} className="btn-up">
                  <VerticalAlignBottomOutlined style={{fontSize: '18px'}}/> {' '} Sample File
                </button>
              </div>

              {isTransactionHash && (
                <div className="mt-15 group-upload">
                  <div className="row-hash">
                    <div className="text-hash"> Transaction Hash: </div>
                    <div className="hash">
                      <a
                        href={`${[
                          SCAN_URL[chainId],
                        ]}/tx/${isTransactionHash.toLowerCase()}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {isTransactionHash}
                        <img
                          className="link-scan"
                          src="../images/bot/linkscan.png"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              )}
              
              {!account ? (
                <>
                  <Alert description="Please connect wallet." className="text-center mt-10" type="error"/>
                </>
              ):(
                <>
                  {chainId && MAPPING_CHAINID_DECIMAL.hela !== chainId ? (
                    <>
                      <Alert description={`Wrong network, Please switch to ${CHAINID_FULLNAME_BY_ID[MAPPING_CHAINID_DECIMAL.hela]}`} className="text-center mt-10" type="error"/>
                    </>
                  ):(
                    <>
                      <div className="group-btn mar-top-30">
                        {isApprove ? (
                          <button
                            type="button"
                            disabled={
                              !tokenAddress || !account || isApproving || !tokenInfo
                            }
                            onClick={handleApprove}
                            className="btn-add click-now w-200"
                          >
                            <Loading status={isApproving} content="Approve" />
                          </button>
                        ) : (
                          <>
                            <button
                              disabled={
                                !account ||
                                isConfirming ||
                                !tokenAddress ||
                                !recipients ||
                                !tokenInfo ||
                                Number(totalValue) > Number(tokenInfo?.balanceOf) ||
                                addresses.length > 200
                              }
                              type="button"
                              onClick={handleMultisend}
                              className="btn-add click-now w-200"
                            >
                              <Loading status={isConfirming} content="Confirm" />
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterHome />
      {isConfirming && <Loader />}
    </>
  );
};
export default MultiSender;
